<template>
    <div>
        <header-portrate />
    <div class="m-2" style="margin-bottom:70px !important;">
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" />

        <v-row :style="`direction:`+lang.dir">
            <v-col
            cols="12"
            md="8"
            sm="12">
            <b-input-group class="mt-3">
                <v-select
                    item-text="text"
                    item-value="value"
                    style="width:0 !important;height:10px;"
                    v-model="account"
                    :items="accounts"
                    :label="lang.accounts"
                    @change="getaccountname()"
                    ></v-select>
                <b-form-input placeholder="Start Date" style="background:#eee" type="date" v-model="sdate"></b-form-input>
                <b-form-input placeholder="End Date" style="background:#eee" type="date" v-model="edate"></b-form-input>
                <b-input-group-append style="border-left:1px solid #CCC">
                    <b-button variant="light" style="background: #ccc !important;font-size:14px;color:#000;font-size:14px;padding:8px 22px;border-radius:5px" @click="getReport()"><i class="fas fa-search" style="color:000;font-weight:bold;"></i></b-button>
                </b-input-group-append>
            </b-input-group>
            </v-col>
            <v-col cols="12" md="4" sm="12" class="text-right">
                <b-button variant="light" style="width:145px;margin-right:9px" class="mytab mytab1 mt-3" @click="exportE('e')">{{lang.export_excel}}</b-button>
                <b-button variant="light" style="width:145px;margin-right:3px;background:red;color:#FFF;display:none" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12" sm="12">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="5" class="text-center">{{lang.account_statement}} - {{account_name}}</th>
                        </tr>
                        <tr>
                            <th class="text-center">{{lang.date}}</th>
                            <th class="text-center">{{lang.description}}</th>
                            <th class="text-center">{{lang.depit}}</th>
                            <th class="text-center">{{lang.credit}}</th>
                            <th class="text-center">{{lang.balance}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in accountrows" :key="index">
                            <td class="text-center" style="direction:ltr">{{item.date}}</td>
                            <td class="text-center" style="direction:ltr">{{item.notes}}</td>
                            <td class="text-center" style="direction:ltr">{{item.depit}}</td>
                            <td class="text-center" style="direction:ltr">{{item.credit}}</td>
                            <td class="text-center" style="direction:ltr">{{item.balance}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colspan="5"> {{lang.balance}} : {{btotals}} </th>
                        </tr>
                    </tfoot>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
    <Footer />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import axios from 'axios'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default {
  components: { breadCrumbs ,TabsComp, HeaderPortrate, Footer},
    data()
     {
        return {
            sdate: new Date().toISOString().substring(0, 10),
            edate: new Date().toISOString().substring(0, 10),
            accountrows: [],
            accounts: [],
            account: 0,
            account_name: '',
        }
    },
    computed: {
        
        btotals: function(){
            let t = 0;
            for(let i=0;i<this.accountrows.length;i++){
                t = parseFloat(t) + parseFloat(this.accountrows[i].balance)
            }
            t = this.$RoundNum(t);
            if(t < 0){
                return "("+t+")";
            }else{
                return t;
            }
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return {
                    text: this.lang.account_statement,
                    disabled: true,
                    to: '/reports/statment-report',
                }
        },
        tabs: function() {
            let tabs =  [
                {
                    index:3,name:this.lang.vat_report,href:'/reports/vat-reports',class:'mytab3',smclass:'font-small'
                },
               
            ];

            tabs.push(
                {
                    index:4,name:this.lang.income_report,href:'/reports/income-report',class:'mytab3',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:7,name:this.lang.account_statement,href:'/reports/statment-report',class:'mytab3',smclass:'font-small'
                }
            );
            
            tabs.push(
                {
                    index:8,name:this.lang.account_balance_report,href:'/reports/balance-report',class:'mytab3',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:8,name:this.lang.bnl_report,href:'/reports/bnl-report',class:'mytab',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:8,name:this.lang.balance_report,href:'/reports/acbalance-report',class:'mytab3',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:2,name:this.lang.sales_report,href:'/reports/sales-reports',class:'mytab3',smclass:'font-small'
                }
            );
            
            tabs.push({
                    index:5,name:this.lang.return_report,href:'/reports/return-reports',class:'mytab6',smclass:'font-small'
                }
            );
            tabs.push({
                    index:0,name:this.lang.received_voucher,href:'/finance/inreceipt',class: 'mytab4',smclass:'font-small'
                }
            );
            tabs.push({
                    index:1,name:this.lang.exchange_voucher,href:'/finance/outreceipt',class: 'mytab5',smclass:'font-small'
                }
            );
            return tabs;
        },
        headers: function(){
            return '';
        }
    },
    created() {
        this.getAccounts();
    },
    methods: {
        getaccountname(){
            this.accountrows = [];
            if(this.accoutn == 0){
                return false;
            }
            for(let i =0 ; i<this.accounts.length;i++){
                if(this.accounts[i].value == this.account){
                    this.account_name = this.accounts[i].text
                }
            }
        },
        getAccounts(){
            const post = new FormData();
            post.append('type',"getAccoutns");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                this.accounts = res.results.data;
            })
        },  
        getReport(){
            const post = new FormData();
            post.append('type',"getStatReport");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            post.append('data[account]',this.account);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                if(typeof res.results.data !== 'undefined')
                {
                    this.accountrows = res.results.data;
                }
            })
        }
    },
}
</script>